import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "configure-ui-colors"
    }}>{`Configure UI Colors`}</h1>
    <hr></hr>
    <p>{`To configure the UI colors for your mobile app or template, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Mobile Apps section from the left side menu options. Click on the mobile app or template you wish to edit.`}</li>
      <li parentName="ol">{`Scroll down to the Default Colors section.`}</li>
      <li parentName="ol">{`Set the colors:`}
        <ul parentName="li">
          <li parentName="ul">{`Theme specifies the background color used throughout your app. `}</li>
          <li parentName="ul">{`Homescreen header cover the color of the home screen header box. This displays while the patron is on the home screen of your app. `}</li>
          <li parentName="ul">{`Homescreen button details the colors of the buttons found on the home screen of your app. MY BARCODE, SELF-CHECK, and MORE are examples of these buttons. `}</li>
          <li parentName="ul">{`Notice Button details the colors of the notice buttons found on the home screen of your app. These buttons include OVERDUE only at this time. `}</li>
          <li parentName="ul">{`Link Button details the colors of the link buttons found throughout your app. `}</li>
          <li parentName="ul">{`Device Light/Dark mode relates to the settings of the patron's device. Depending on which setting they have configured, the app will honor this and display accordingly. `}</li>
        </ul>
      </li>
    </ol>
    <p><img alt="CMS Color Options" src={require("./images/cms_color_options.png")} />{` `}<img alt="App Color Options Reference 1" src={require("./images/app_color_options1.png")} />{`
`}<img alt="App Color Options Reference 2" src={require("./images/app_color_options2.png")} />{` `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      